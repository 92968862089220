import * as THREE from 'three'
import Experience from '../Experience.js';

export default class PointLight
{
    constructor(name, params)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        
        this.params = params
        this.debugParams = 
        {
            color: '#ff9500',
        }

        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder(name)
            this.debugFolder.close()
        }

        this.setLight()
        this.setParams()
        
    }

    setLight()
    {
        this.light = new THREE.PointLight(this.debugParams.color, 1)
        this.light.castShadow = false // General is true, but for this project I want false
        this.light.shadow.mapSize.set(2048, 2048)
        this.light.position.set(0, 0, 0)

        this.params && this.params.hasOwnProperty('group') 
        ? this.params.group.add(this.light) 
        : this.scene.add(this.light);

        // Debug
        if(this.debug.active)
        {
            this.debugFolder.add(this.light, 'intensity')
            .name('lightIntensity')
            .min(0)
            .max(10)
            .step(0.001)

            this.debugFolder.addColor(this.debugParams, 'color').onChange(()=>
            {
                this.light.color.set(this.debugParams.color)
            })

            this.debugFolder.add(this.light.position, 'x')
            .name('lightX')
            .min(-50)
            .max(50)
            .step(0.001)

            this.debugFolder.add(this.light.position, 'y')
            .name('lightY')
            .min(-50)
            .max(50)
            .step(0.001)

            this.debugFolder.add(this.light.position, 'z')
            .name('lightZ')
            .min(-50)
            .max(50)
            .step(0.001)
        }
    }

    setParams()
    {
        if(this.params)
        {
            for(let property in this.params)
            {
                // console.log(property); // Key
                // console.log(this.params[property]); // Value

                if(typeof this.params[property] === 'object')
                {
                    // group property is used when we want to add the model to a group instead the scene
                    if(property == 'group'){return}
                    
                    // Set property inside properties (rotation.x)
                    // Object inside Object (sub Property)
                    for( let subProperty in this.params[property])
                    {
                        this.light[property][subProperty] = this.params[property][subProperty]
                    }
                }
                else
                {
                    // Set normal property
                    this.light[property] = this.params[property]
                }
            }
        }
    }
}