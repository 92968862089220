import * as THREE from "three"
import Experience from "../Experience.js";

export default class Floor
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        this.params = 
        {
            color: '#d0ac76',
        }

        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('floor')
            this.debugFolder.close()
        }

        this.setGeometry()
        // this.setTextures()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry()
    {
        this.geometry = new THREE.PlaneGeometry(256, 256)
    }
    
    setTextures()
    {
        this.textures = {}
        
        this.textures.color = this.resources.items.grassColorTexture
        this.textures.color.encoding = THREE.sRGBEncoding
        this.textures.color.repeat.set(1.5, 1.5)
        this.textures.color.wrapS = THREE.RepeatWrapping
        this.textures.color.wrapT = THREE.RepeatWrapping

        this.textures.normal = this.resources.items.grassNormalTexture
        this.textures.normal.repeat.set(1.5, 1.5)
        this.textures.normal.wrapS = THREE.RepeatWrapping
        this.textures.normal.wrapT = THREE.RepeatWrapping

    }

    setMaterial()
    {
        this.material = new THREE.MeshStandardMaterial({
            color: this.params.color,
        })
    
        if(this.debug.active)
        {
            this.debugFolder.addColor(this.params, 'color').onChange(()=>
            {
                this.material.color.set(this.params.color)
            })
        }
    }
    setMesh()
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.rotation.x = - Math.PI * 0.5
        this.mesh.position.y = -2
        this.mesh.receiveShadow = true
        this.scene.add(this.mesh)
    }
}