export default [
    {
        name: 'island',
        type: 'gltfModel',
        path: 'models/island.glb'
    },

    {
        name: 'tree',
        type: 'gltfModel',
        path: 'models/tree.glb'
    },

    {
        name: 'computerDesk',
        type: 'gltfModel',
        path: 'models/computerDesk.glb'
    },

    {
        name: 'chair',
        type: 'gltfModel',
        path: 'models/chair.glb'
    },

    {
        name: 'sofa',
        type: 'gltfModel',
        path: 'models/sofa.glb'
    },

    {
        name: 'table',
        type: 'gltfModel',
        path: 'models/table.glb'
    },

    {
        name: 'cabinet',
        type: 'gltfModel',
        path: 'models/cabinet.glb'
    },
]