import 'latest-createjs'
import * as THREE from 'three'
import Experience from "../Experience.js"

export default class BasicModel
{
    constructor(name, params)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.params = params

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder(name)
            this.debugFolder.close()
        }

        // Setup
        this.resource = this.resources.items[name]

        this.setModel()
        this.setParams()
        this.resize()
    }

    setModel()
    {
       this.model = this.resource.scene
       this.model.scale.set(1, 1, 1)
       this.model.position.y = -2
       
       this.defaultScale = 1
       
       this.params && this.params.hasOwnProperty('group') 
       ? this.params.group.add(this.model) 
       : this.scene.add(this.model)

       this.model.traverse((child)=>
       {
        if(child instanceof THREE.Mesh)
        {
            child.castShadow = true
        }
       })

       if(this.debug.active)
       {
        this.debugFolder.add(this.model.position, 'x').min(-50).max(50).step(0.001)
        this.debugFolder.add(this.model.position, 'y').min(-50).max(50).step(0.001)
        this.debugFolder.add(this.model.position, 'z').min(-50).max(50).step(0.001)
        this.debugFolder.add(this.model.rotation, 'x').min(0).max(Math.PI * 2).step(0.001).name('rotationX')
        this.debugFolder.add(this.model.rotation, 'y').min(0).max(Math.PI * 2).step(0.001).name('rotationY')
        this.debugFolder.add(this.model.rotation, 'z').min(0).max(Math.PI * 2).step(0.001).name('rotationZ')
       }
    }

    setParams()
    {
        if(this.params)
        {
            for(let property in this.params)
            {
                // console.log(property); // Key
                // console.log(this.params[property]); // Value

                if(typeof this.params[property] === 'object')
                {
                    // group property is used when we want to add the model to a group instead the scene
                    if(property == 'group'){return}

                    // copy scale property for default scale
                    if(property == 'scale')
                    {
                        this.defaultScale = this.params[property]
                    }

                    // Set property inside properties (rotation.x)
                    // Object inside Object (sub Property)
                    for( let subProperty in this.params[property])
                    {
                        this.model[property][subProperty] = this.params[property][subProperty]
                    }
                }
                else
                {
                    // Set normal property
                    this.model[property] = this.params[property]
                }
            }
        }
    }

    scaleIn(waitTime = 0, scale = this.defaultScale)
    {
        ['x', 'y', 'z'].forEach((dimension)=>
        {
            createjs.Tween.get(this.model.scale).wait(waitTime).to({[dimension]: scale}, 700, createjs.Ease.backOut)
        })
    }

    scaleOut(waitTime = 0)
    {
        ['x', 'y', 'z'].forEach((dimension)=>
        {
            createjs.Tween.get(this.model.scale).wait(waitTime).to({[dimension]: 0}, 500, createjs.Ease.backIn)
        })
    }

    resize()
    {
        // window.innerWidth for responsive
    }

    update()
    {
        // Models with Animation
    }
}