import 'latest-createjs'
import * as THREE from 'three'
import Experience from '../Experience.js';

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        
        this.params = 
        {
            color: '#fbd0a7',
            fogColor: '#9ba9b1',
            fogNear: 40,
            forgFar: 110
        }

        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('environment')
            this.debugFolder.close()
        }

        this.setSunLight()
        this.setMoonLight()
        this.setAmbientLight()
        // this.setEnvironmentMap()
        // this.setFog()
    }

    setSunLight()
    {
        this.sunLight = new THREE.DirectionalLight(this.params.color, 2)
        this.sunLight.castShadow = true

        this.sunLight.shadow.camera.far = 50
        this.sunLight.shadow.mapSize.set(2048 * 2, 2048 * 2)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(5, 5, 3.4)
        
        this.sunLight.shadow.camera.left = -30
        this.sunLight.shadow.camera.right = 30
        this.sunLight.shadow.camera.top = 30
        this.sunLight.shadow.camera.bottom = -30

        this.scene.add(this.sunLight)
        
        // this.lightHelper = new THREE.CameraHelper(this.sunLight.shadow.camera)
        // this.scene.add(this.lightHelper)


        // Debug
        if(this.debug.active)
        {
            this.debugFolder.add(this.sunLight, 'intensity')
            .name('sunLightIntensity')
            .min(0)
            .max(10)
            .step(0.001)

            this.debugFolder.addColor(this.params, 'color').onChange(()=>
            {
                this.sunLight.color.set(this.params.color)
            })

            this.debugFolder.add(this.sunLight.position, 'x')
            .name('sunLightX')
            .min(-5)
            .max(5)
            .step(0.001)

            this.debugFolder.add(this.sunLight.position, 'y')
            .name('sunLightY')
            .min(-5)
            .max(5)
            .step(0.001)

            this.debugFolder.add(this.sunLight.position, 'z')
            .name('sunLightZ')
            .min(-5)
            .max(5)
            .step(0.001)
        }
    }

    setMoonLight()
    {
        this.moonLight = new THREE.DirectionalLight('#4363e5', 0)
        this.moonLight.castShadow = true

        this.moonLight.shadow.camera.far = 50
        this.moonLight.shadow.mapSize.set(2048 * 2, 2048 * 2)
        this.moonLight.shadow.normalBias = 0.05
        this.moonLight.position.set(5, 5, 3.4)
        
        this.moonLight.shadow.camera.left = -30
        this.moonLight.shadow.camera.right = 30
        this.moonLight.shadow.camera.top = 30
        this.moonLight.shadow.camera.bottom = -30

        this.scene.add(this.moonLight)
        
        // this.lightHelper = new THREE.CameraHelper(this.moonLight.shadow.camera)
        // this.scene.add(this.lightHelper)


        // Debug
        if(this.debug.active)
        {
            this.debugFolder.add(this.moonLight, 'intensity')
            .name('moonLightIntensity')
            .min(0)
            .max(10)
            .step(0.001)

            this.debugFolder.addColor(this.params, 'color').onChange(()=>
            {
                this.moonLight.color.set(this.params.color)
            })

            this.debugFolder.add(this.moonLight.position, 'x')
            .name('moonLightX')
            .min(-5)
            .max(5)
            .step(0.001)

            this.debugFolder.add(this.moonLight.position, 'y')
            .name('moonLightY')
            .min(-5)
            .max(5)
            .step(0.001)

            this.debugFolder.add(this.moonLight.position, 'z')
            .name('moonLightZ')
            .min(-5)
            .max(5)
            .step(0.001)
        }
    }

    setAmbientLight()
    {
        this.ambientLight = new THREE.AmbientLight('#ffffff', 0.2)
        this.scene.add(this.ambientLight)
        
        // Debug
        if(this.debug.active)
        {
            this.debugFolder.add(this.ambientLight, 'intensity')
            .name('ambientLightIntensity')
            .min(0)
            .max(10)
            .step(0.001)

            this.debugFolder.addColor(this.params, 'color').onChange(()=>
            {
                this.ambientLight.color.set(this.params.color)
            })
        }
    }

    lightMode()
    {
        createjs.Tween.get(this.sunLight).to({intensity: 2}, 300)
        createjs.Tween.get(this.moonLight).to({intensity: 0}, 300)
        createjs.Tween.get(this.ambientLight).to({intensity: 0.2}, 300)
    }

    darkMode()
    {
        createjs.Tween.get(this.sunLight).to({intensity: 0}, 300)
        createjs.Tween.get(this.moonLight).to({intensity: 0.1}, 300)
        createjs.Tween.get(this.ambientLight).to({intensity: 0.05}, 300)
    }

    // setEnvironmentMap()
    // {
    //     this.environmentMap = {}
    //     this.environmentMap.intensity = 0.4
    //     this.environmentMap.texture = this.resources.items.environmentMapTexture
    //     this.environmentMap.texture.encoding = THREE.sRGBEncoding

    //     this.scene.environment = this.environmentMap.texture

    //     this.environmentMap.updateMaterials = ()=>
    //     {
    //         this.scene.traverse((child)=>{
    //             if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
    //             {
    //                 child.material.envMap = this.environmentMap.texture
    //                 child.material.envMapIntensity = this.environmentMap.intensity
    //                 child.material.needsUpdate = true
    //             }
    //         })
    //     }

    //     this.environmentMap.updateMaterials()

    //     // Debug
    //     if(this.debug.active)
    //     {
    //         this.debugFolder.add(this.environmentMap, 'intensity')
    //         .name('envMapIntensity')
    //         .min(0)
    //         .max(4)
    //         .step(0.001)
    //         .onChange(this.environmentMap.updateMaterials)
    //     }
    // }
    
    // setFog()
    // {
    //     this.fog = new THREE.Fog(this.params.fogColor, this.params.fogNear, this.params.forgFar)
    //     this.scene.fog = this.fog

    //     if(this.debug.active)
    //     {
    //         this.debugFolder.addColor(this.params, 'fogColor').onChange(()=>
    //         {
    //             this.fog.color.set(this.params.fogColor)
    //         })

    //         this.debugFolder.add(this.fog, 'near')
    //         .name('fogNear')
    //         .min(0)
    //         .max(100)
    //         .step(0.001)

    //         this.debugFolder.add(this.fog, 'far')
    //         .name('fogFar')
    //         .min(0)
    //         .max(100)
    //         .step(0.001)
    //     }
    // }
}