import 'latest-createjs'
import * as THREE from 'three'
import Experience from '../Experience.js'
import Environment from './Environment.js'
import Particles from './Particles.js'
import Floor from './Floor.js'
import BasicModel from './BasicModel.js'
import Trees from './Trees.js'
import PointLight from './PointLight.js'
import Points from './Points.js'
// import WaterShader from '../Shaders/WaterShader.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.time = this.experience.time
        this.debug = this.experience.debug
        
        this.elements = []
        this.updateElements = []
        this.isMobile

        this.resources.on('ready', ()=>
        {
            // Setup
            this.environment = new Environment()
            this.floor = new Floor()
            this.particles = new Particles()
            this.points = new Points()
            
            // Build World
            this.setModels()
            this.setLights()
            this.scene.background = new THREE.Color('#c9ba9d')
            // this.darkMode()
        })
    }

    setModels()
    {
        this.island = new BasicModel('island', {scale: {x: 0.8, y: 0.8, z: 0.8}, position: {y: -1.4}, rotation: {y: Math.PI * 0.5}})

        this.trees = new Trees('tree')
        
        const scaleFurniture = 0.65
        const groundPos = -1.18
        this.computerDesk = new BasicModel('computerDesk', 
        {
            scale: {x: scaleFurniture, y: scaleFurniture, z: scaleFurniture}, 
            position: {x: 0.42, y: groundPos, z: 2.7},  
            rotation: {y: Math.PI * 0.77}
        })

        this.chair = new BasicModel('chair', 
        {
            scale: {x: scaleFurniture, y: scaleFurniture, z: scaleFurniture}, 
            position: {x: 1.2, y: groundPos, z: 2.18},
            rotation: {y: Math.PI * 1.78}
        })

        this.sofa = new BasicModel('sofa', 
        {
            scale: {x: scaleFurniture, y: scaleFurniture, z: scaleFurniture}, 
            position: {x: -1.46, y: groundPos, z: -0.31},
            rotation: {y: Math.PI * 0.48}
        })

        this.table = new BasicModel('table', 
        {
            scale: {x: scaleFurniture, y: scaleFurniture, z: scaleFurniture}, 
            position: {x: -0.14, y: groundPos, z: -0.23},
            rotation: {y: Math.PI * 0.48}
        })

        this.cabinet = new BasicModel('cabinet', 
        {
            scale: {x: scaleFurniture, y: scaleFurniture, z: scaleFurniture}, 
            position: {x: 1.29, y: groundPos, z: -1.66},
            rotation: {y: Math.PI * 1.8}
        })
    }

    setLights()
    {
        this.deskLamp = new PointLight('deskLamp')
        this.deskLamp.light.position.set(0, -0.02 - 0.1, 2.12)
        this.deskLamp.light.intensity = 0
    }

    lightMode()
    {
        this.environment.lightMode()
        this.points.lightMode()
        
        createjs.Tween.get(this.scene.background, {override: true}).to({
            r: 0.788,
            g: 0.729,
            b: 0.615
        }, 300)

        createjs.Tween.get(this.deskLamp.light).to({intensity: 0}, 300)  
    }

    darkMode()
    {
        this.environment.darkMode()
        this.points.darkMode()

        createjs.Tween.get(this.scene.background, {override: true}).wait(79).to({
            r: 0.145,
            g: 0.133,
            b: 0.133
        }, 300)

        createjs.Tween.get(this.deskLamp.light).wait(300).to({intensity: 0.8}, 300)
    }

    resetNavigation()
    {
        this.camera.controls.enabled = true
        this.cameraStart()
        this.points.show(700)
    }

    navigation(section)
    {
        this.camera.controls.enabled = false 
        this.points.hide()

        switch(section)
        {
            case 'projects':
                this.cameraProjects()
                break
            case 'about':
                this.cameraAbout()
                break
            case 'contact':
                this.cameraContact()
                break
        }
    }

    cameraStart()
    {
        this.camera.changeView
        (
            this.camera.startPos, 
            0, 
            2400,
            createjs.Ease.sineInOut,
            new THREE.Vector3(0, 0, 0)
        )
        
        
        setTimeout(()=>{
            this.points.canUpdate = true 
        }, 2400)
    }

    cameraProjects()
    {   
        if(this.isMobile)
        {
            this.camera.changeView
            (
                new THREE.Vector3(4.3, 0.46, -1.46), 
                0, 
                2400, 
                createjs.Ease.sineInOut, 
                new THREE.Vector3(1.2, -0.12, 1.79)
            )
        }
        else
        {
            this.camera.changeView
            (
                new THREE.Vector3(2.2, 0, 0.67), 
                0, 
                2400, 
                createjs.Ease.sineInOut, 
                new THREE.Vector3(0.3, 0.1, 2.75)
            )
        }
    }

    cameraAbout()
    {
        if(this.isMobilee)
        {
            this.camera.changeView
            (
                new THREE.Vector3(-4.88, 1.88, 0.76), 
                0, 
                2400, 
                createjs.Ease.sineInOut, 
                new THREE.Vector3(-0.32, -0.62, -0.2)
            ) 
        }
        else
        {
            this.camera.changeView
            (
                new THREE.Vector3(-3.17, 0.68, 0.37), 
                0, 
                2400, 
                createjs.Ease.sineInOut, 
                new THREE.Vector3(-0.53, -0.55, -0.1)
            )
        }
    }

    cameraContact()
    {
        if(this.isMobile)
        {
            this.camera.changeView
            (
                new THREE.Vector3(-1.58, 0.65, 2.59), 
                0, 
                2400, 
                createjs.Ease.sineInOut, 
                new THREE.Vector3(0.33, -0.1, -0.25)
            ) 
        }
        else
        {
            this.camera.changeView
            (
                new THREE.Vector3(-0.17, 0, 0.35), 
                0, 
                2400, 
                createjs.Ease.sineInOut, 
                new THREE.Vector3(1, -0.2, -1.24)
            ) 
        }
    }

    resize()
    {
        this.elements.forEach((element)=>
        {
            if(element)
            {
                element.resize()
            }
        })
    }

    update()
    {        
        this.updateElements.forEach((element)=>
        {
            if(element)
            {
                element.update()
            }

        })

        if(this.particles)
        {
            this.particles.update()
        }

        if(this.points && window.innerWidth >= 1024)
        {
            this.points.update()
        }
    }
}