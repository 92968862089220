import * as THREE from 'three'
import Experience from "./Experience.js"

export default class Renderer
{
    constructor()
    {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.debug = this.experience.debug
        
        this.params = 
        {
            background: '#d8c9ae'
            // background: '#211905'
        }

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('render')
            this.debugFolder.close()

            this.debugFolder.addColor(this.params, 'background').onChange(()=>
            {
                this.instance.setClearColor(this.params.background)
            })
        }

        this.setInstance()
    }

    setInstance()
    {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antilias: true,
            powerPreference: 'high-performance'
        })
        this.instance.physicallyCorrectLights = true
        this.instance.outputEncoding = THREE.sRGBEncoding
        this.instance.toneMapping = THREE.CineonToneMapping
        this.instance.toneMappingExposure = 1.75
        this.instance.shadowMap.enabled = true
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap
        this.instance.setClearColor(this.params.background)
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)

    }

    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(this.sizes.pixelRatio)

        if(window.innerWidth <= 1024)
        {
            this.instance.antilias = false
        }
        else
        {
            this.instance.antilias = true
        }
    }

    update()
    {
        this.instance.render(this.scene, this.camera.instance)
    }
}