import 'latest-createjs'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Experience from "./Experience.js";

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.debug = this.experience.debug

        this.startPos
        this.isAnimating = false
        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('camera')
            this.debugFolder.close()
        }

        // Setup
        this.focusOrigin = new THREE.Vector3(0, 0, 0)
        this.setInstance()
        this.setOrbitControls()
    }
    
    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.sizes.width / this.sizes.height,
            0.1,
            100
        )
        this.startPos = window.innerWidth < 1024 ? new THREE.Vector3(12.3 * 2.2, 4 * 2.2, 2 * 2.2) : new THREE.Vector3(12.3, 4, 2)
        window.innerWidth < 1024 ? this.instance.position.set(30 * 2.2, 3.4 * 2.2, -30 * 2.2) : this.instance.position.set(30, 3.4, -30)

        if(this.debug.active)
        {
            this.debugFolder.add(this.instance.position, 'x').min(-30).max(30).step(0.001)
            this.debugFolder.add(this.instance.position, 'y').min(-30).max(30).step(0.001)
            this.debugFolder.add(this.instance.position, 'z').min(-30).max(30).step(0.001)
        }
        
        this.scene.add(this.instance)
    }

    setOrbitControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        this.controls.enablePan = false
        this.controls.minDistance = 2
        this.controls.maxDistance = 30
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        this.controls.update()
    }
    
    changeView(
        pos, 
        wait = 0,
        duration = 3200, 
        easing = createjs.Ease.sineInOut, 
        focus = false,
        )
    {
        this.isAnimating = true
        setTimeout(()=>
        {
            this.isAnimating = false
        }, wait + duration)
        
        if(focus != false && focus != undefined)
        {
            createjs.Tween.get(this.controls.target, {override: true}).wait(wait).to(focus, duration, easing)
        }
        
        createjs.Tween.get(this.instance.position, {override: true}).wait(wait).to(pos, duration, easing)
    }

    getInfo()
    {
        console.log('Camera Position:', this.instance.position);
        console.log('Camera Direction:', this.controls.target);
    }
}