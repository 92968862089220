import 'latest-createjs'
import * as THREE from 'three'
import Experience from "../Experience.js"

export default class Points
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.sizes = this.experience.sizes
        this.canUpdate = false
        
        this.generatePoints()
        this.setPoints()
    }

    generatePoints()
    {
        const pointsNumber = 3
        const htmlInterface = document.querySelector('.interface')

        for (let i = 0; i < pointsNumber; i++) {
            const pointDiv = document.createElement('div');
            const pointButton = document.createElement('button')

            pointDiv.classList.add('point', `point-${i}`, 'lightMode')
            pointButton.classList.add('label')

            pointDiv.appendChild(pointButton)
            document.body.insertBefore(pointDiv, htmlInterface);
          }
    }

    setPoints()
    {
        this.raycaster = new THREE.Raycaster()
        this.points = [
            {
                position: new THREE.Vector3(-1.5, 0.24, 0.05),
                element: document.querySelector('.point-0'),
                textContent: 'About',
                // action: function(){
                //     console.log('Side Projects');
                // }
            },

            {
                position: new THREE.Vector3(1.5, 0.3, -1.5),
                element: document.querySelector('.point-1'),
                textContent: 'Contact',
                // action: function(){
                //     console.log('About Me');
                // }
            },

            {
                position: new THREE.Vector3(0.8, 0.75, 3),
                element: document.querySelector('.point-2'),
                textContent: 'Projects',
                // action: function(){
                //     console.log('Case Studies');
                // }
            },
        ]

        for(let i = 0; i < this.points.length; i++)
        {
            const point = this.points[i].element.firstChild
            point.textContent = this.points[i].textContent
            // point.addEventListener('click', this.points[i].action)
        }
    }

    lightMode()
    {
        this.points.forEach((i)=>
        {
            let currPoint = i.element
            currPoint.classList.remove('darkMode')
            currPoint.classList.add('lightMode')
        }) 
    }

    darkMode()
    {
        this.points.forEach((i)=>
        {
            let currPoint = i.element
            currPoint.classList.add('darkMode')
            currPoint.classList.remove('lightMode')
        })
    }

    hide()
    {
        this.canUpdate = false
        for(const point of this.points)
        {
            point.element.classList.remove('visible')
        }
    }

    show(delay)
    {
        setTimeout(()=>
        {
            this.canUpdate = true
        }, delay)
    }

    update()
    {
        if(this.canUpdate)
        {
            for(const point of this.points)
            {
                const screenPosition = point.position.clone()
                screenPosition.project(this.camera.instance)
        
                this.raycaster.setFromCamera(screenPosition, this.camera.instance)
                //Objects to Intersect -> Island and Trees
                this.objectsToIntersect = [this.scene.children[6], this.scene.children[7], this.scene.children[8], this.scene.children[9]]
                const intersects = this.raycaster.intersectObjects(this.objectsToIntersect, true)
    
                if(intersects.length === 0)
                {
                    point.element.classList.add('visible')
                }
                else
                {
                    const intersctionDistance = intersects[0].distance
                    const pointDistance = point.position.distanceTo(this.camera.instance.position)
        
                    if(intersctionDistance < pointDistance)
                    {
                        point.element.classList.remove('visible')
                    }
                    else
                    {
                        point.element.classList.add('visible')
                    }
                }
        
                const translateX = screenPosition.x * this.sizes.width * 0.5
                const translateY = -screenPosition.y * this.sizes.height * 0.5
                point.element.style.transform = `translate(${translateX}px, ${translateY}px)`
            }
        }
    }
}