import * as THREE from 'three';
import Experience from '../Experience.js';
import particlesFragmentShader from '../Shaders/glsl/particles/fragment.glsl';
import particlesVertexShader from '../Shaders/glsl/particles/vertex.glsl';

export default class Particles
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.params = 
        {
            insideColor: '#dddf86',
            outsideColor: '#d7a537',
            size: 0.1,
            count: 700,
            distance: 40,
            randomness: 0.5,
            randomnessPower: 3
        }

        //Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('particles')
            this.debugFolder.close()
            
            this.debugFolder.addColor(this.params, 'insideColor').onFinishChange(()=>
            {
                this.generate()
            })

            this.debugFolder.addColor(this.params, 'outsideColor').onFinishChange(()=>
            {
                this.generate()
            })

            this.debugFolder.add(this.params, 'size').min(0.001).max(1).step(0.001).name('size').onFinishChange(()=>{this.generate()})
            this.debugFolder.add(this.params, 'count').min(200).max(200000).step(0.001).name('count').onFinishChange(()=>{this.generate()})
            this.debugFolder.add(this.params, 'distance').min(1).max(256).step(0.01).name('distance').onFinishChange(()=>{this.generate()})
        }

        //Setup
        this.geometry = null
        this.material = null
        this.points = null

        this.generate()

    }

    generate()
    {
        // Destroy old particles
        if(this.points !== null)
        {
            this.geometry.dispose()
            this.material.dispose()

            this.scene.remove(this.points)
        }

        // Geometry
        this.geometry = new THREE.BufferGeometry()
        
        this.positions = new Float32Array(this.params.count * 3)
        this.colors = new Float32Array(this.params.count * 3)
        this.scales = new Float32Array(this.params.count * 1)
        this.randomness = new Float32Array(this.params.count * 3)

        const insideColor = new THREE.Color(this.params.insideColor)
        const outsideColor = new THREE.Color(this.params.outsideColor)

        for(let i = 0; i < this.params.count; i++)
        {
            const i3 = i * 3
            
            // this.positions[i] = (Math.random() - 0.5) * this.params.distance

            this.positions[i3    ] = (Math.random() - 0.5) * this.params.distance
            this.positions[i3 + 1] = (Math.random() - 0.5) * this.params.distance
            this.positions[i3 + 2] = (Math.random() - 0.5) * this.params.distance

            this.scales[i] = Math.random()

            
            // Color
            const mixedColor = insideColor.clone()
            mixedColor.lerp(outsideColor, Math.random())

            this.colors[i3 + 0] = mixedColor.r
            this.colors[i3 + 1] = mixedColor.g
            this.colors[i3 + 2] = mixedColor.b


            // Randomness
            const randomX = Math.pow(Math.random(), this.params.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.params.randomness
            const randomY = Math.pow(Math.random(), this.params.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.params.randomness
            const randomZ = Math.pow(Math.random(), this.params.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.params.randomness

            this.randomness[i3 + 0] = randomX
            this.randomness[i3 + 1] = randomY
            this.randomness[i3 + 2] = randomZ
        }

        this.geometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3))
        this.geometry.setAttribute('color', new THREE.BufferAttribute(this.colors, 3))
        this.geometry.setAttribute('aScale', new THREE.BufferAttribute(this.scales, 1))
        this.geometry.setAttribute('aRandomness', new THREE.BufferAttribute(this.randomness, 3))

        // Material
        this.material = new THREE.ShaderMaterial({
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,            
            vertexShader: particlesVertexShader,
            fragmentShader: particlesFragmentShader,
            uniforms:
            {
                uTime: {value: 0},
                uSize: { value: 60 * this.experience.renderer.instance.getPixelRatio() }
            }
        })

        // Points
        this.points = new THREE.Points(this.geometry, this.material)
        this.scene.add(this.points)
    }

    update()
    {
        this.material.uniforms.uTime.value = this.time.elapsed * 0.001
    }
}