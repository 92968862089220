export default [
    {
        name: 'AR Integration',
        description: 'Crafting Immersive interaction and how Augmented Reality becomes a storytelling companion, enriching games with a touch of magic.',
        shortDescription: 'How Augmented Reality becomes a storytelling companion.',
        labels: ["Desk Research", "User Testing", "User Journey Map",  "Unity Game Engine"],
        imagePath: "interface/projects/arApp.webp",
        bgColor: "#3d7a7f",
        url: "https://cat-paint-bc2.notion.site/AR-Integration-9d3bdb6da8654b018ba97591cfb19064?pvs=4"
    },
    {
        name: 'Phygital Experience',
        description: 'Transforming the experience of reading manuals and the way children experience and engage with scientific kits.',
        shortDescription: 'Transforming the experience of reading manuals.',
        labels: ["Benchmark", "Persona", "User Journey Map", "Web Development"],
        imagePath: "interface/projects/phygital.webp",
        bgColor: "#BEDC7F",
        url: "https://alchimistecodex.netlify.app/"
    },
    {
        name: 'Gamification',
        description: 'An interactive article about gamification using pixel art and vanilla Javascript.',
        shortDescription: 'An interactive article about gamification.',
        labels: ["Web Development", "Art Direction"],
        imagePath: "interface/projects/gamification.webp",
        bgColor: "#3c9f9c",
        url: "https://gamificationfernandcamps.netlify.app/"
    },
    {
        name: 'Accessibility App - Polar',
        description: "Polar is an app to improve the accessibility and independence of people with disabilities in the São Paulo subway.",
        shortDescription: 'Improve the indepependance of people in the São Paulo subway.',
        labels: ["UX Research", "Personas", "User Journey Map", "User Flow"],
        imagePath: "interface/projects/polar.webp",
        bgColor: "#225ca3",
        url: "https://cat-paint-bc2.notion.site/Accessibility-App-Polar-fb54020208e34920b2387fffec6061b2"
    },
    {
        name: 'Type Specimen - Calama',
        description: 'Discover the elegance and the story of Calama. Immerse yourself in its design journey, experience captivating animations and interactions.',
        shortDescription: 'Discover the elegance and the story of Calama.',
        labels: ["Web Development"],
        imagePath: "interface/projects/calama.webp",
        bgColor: "#f1eee5",
        url: "https://calama2web.netlify.app/"
    },
    {
        name: 'Memory Pixels',
        description: 'A fun pixel art memory game built with vanilla Javascript. Dive into a nostalgic pixelated adventure.',
        shortDescription: 'Dive into a nostalgic pixelated adventure.',
        labels: ["Web Development", "Art Direction"],
        imagePath: "interface/projects/memoryPixels.webp",
        bgColor: "#a54849",
        url: "https://fernandpixels.itch.io/memory-pixels"
    },
]