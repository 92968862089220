import * as THREE from 'three'
import Experience from "../Experience.js"

export default class Trees
{
    constructor(name)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder("trees")
            this.debugFolder.close()
        }

        // Setup
        this.resource = this.resources.items[name]
        this.setModel()
        this.cloneModel()
    }

    setModel()
    {
       this.model = this.resource.scene
       this.model.position.set(-1.649, -1.19, 3.36)
       this.model.rotation.y = Math.PI * 0.28
       this.scene.add(this.model)

       this.model.traverse((child)=>
       {
        if(child instanceof THREE.Mesh)
        {
            child.castShadow = true
        }
       })

       if(this.debug.active)
       {
        this.debugFolder.add(this.model.position, 'x').min(-50).max(50).step(0.001).name('tree1X')
        this.debugFolder.add(this.model.position, 'y').min(-50).max(50).step(0.001).name('tree1Y')
        this.debugFolder.add(this.model.position, 'z').min(-50).max(50).step(0.001).name('tree1Z')
        this.debugFolder.add(this.model.rotation, 'x').min(0).max(Math.PI * 2).step(0.001).name('tree1RotationX')
        this.debugFolder.add(this.model.rotation, 'y').min(0).max(Math.PI * 2).step(0.001).name('tree1RotationY')
        this.debugFolder.add(this.model.rotation, 'z').min(0).max(Math.PI * 2).step(0.001).name('tree1RotationZ')
       }
    }

    cloneModel()
    {
        this.clone1 = this.model.clone()
        this.clone1.position.set(1.53, -1.19, -3.89)
        this.clone1.rotation.y = Math.PI * 0.62
        this.scene.add(this.clone1)

        if(this.debug.active)
        {
         this.debugFolder.add(this.clone1.position, 'x').min(-50).max(50).step(0.001).name('tree2X')
         this.debugFolder.add(this.clone1.position, 'y').min(-50).max(50).step(0.001).name('tree2Y')
         this.debugFolder.add(this.clone1.position, 'z').min(-50).max(50).step(0.001).name('tree2Z')
         this.debugFolder.add(this.clone1.rotation, 'x').min(0).max(Math.PI * 2).step(0.001).name('tree2RotationX')
         this.debugFolder.add(this.clone1.rotation, 'y').min(0).max(Math.PI * 2).step(0.001).name('tree2RotationY')
         this.debugFolder.add(this.clone1.rotation, 'z').min(0).max(Math.PI * 2).step(0.001).name('tree2RotationZ')
        }

        this.clone2 = this.model.clone()
        this.clone2.position.set(-3.1, -1.19, -1.9)
        this.clone2.rotation.y = Math.PI * 0.52
        this.scene.add(this.clone2)

        if(this.debug.active)
        {
         this.debugFolder.add(this.clone2.position, 'x').min(-50).max(50).step(0.001).name('tree3X')
         this.debugFolder.add(this.clone2.position, 'y').min(-50).max(50).step(0.001).name('tree3Y')
         this.debugFolder.add(this.clone2.position, 'z').min(-50).max(50).step(0.001).name('tree3Z')
         this.debugFolder.add(this.clone2.rotation, 'x').min(0).max(Math.PI * 2).step(0.001).name('tree3RotationX')
         this.debugFolder.add(this.clone2.rotation, 'y').min(0).max(Math.PI * 2).step(0.001).name('tree3RotationY')
         this.debugFolder.add(this.clone2.rotation, 'z').min(0).max(Math.PI * 2).step(0.001).name('tree3RotationZ')
        }
    }
}